import React from "react";
import theme from "theme";
import { Theme, Text, Link, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"gizlilik-politikasi"} />
		<Helmet>
			<title>
				PREMIER LEGAL SOLUTIONS
			</title>
			<meta name={"description"} content={"İhtiyaçlarınıza Özel Güvenilir ve Etkili Hukuk Çözümlerindeki Uzmanlığımızı Keşfedin."} />
			<meta property={"og:title"} content={"PREMIER LEGAL SOLUTIONS"} />
			<meta property={"og:description"} content={"İhtiyaçlarınıza Özel Güvenilir ve Etkili Hukuk Çözümlerindeki Uzmanlığımızı Keşfedin."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab35c82fc7070018ba7c91/images/1-1.jpg?v=2024-08-01T07:17:17.940Z"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			{"    "}{"    "}
			<Text
				as="h2"
				font="--headline1"
				md-font="--headline2"
				margin="20px 0 0 0"
				text-align="center"
			>
				{"        "}Gizlilik Politikası{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				{"        "}PREMIER LEGAL SOLUTIONS olarak, kişisel verilerinizin gizliliğine ve güvenliğine büyük önem veriyoruz. Bu gizlilik politikası, web sitemizi kullanırken topladığımız bilgilerin nasıl kullanıldığını ve korunduğunu açıklamaktadır. Web sitemizi ziyaret ederek, bu politikada belirtilen uygulamaları kabul etmiş olursunuz.{"\n    "}
			</Text>
			{"    \n    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Topladığımız Bilgiler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Web sitemizi kullandığınızda, sizinle ilgili çeşitli bilgiler toplayabiliriz. Bu bilgiler, adınız, e-posta adresiniz, telefon numaranız gibi kişisel bilgileri içerebilir. Ayrıca, IP adresiniz, tarayıcı türünüz ve ziyaret ettiğiniz sayfalar gibi otomatik olarak toplanan bilgiler de dahildir. Bu bilgiler, web sitemizi iyileştirmek, hizmetlerimizi sunmak ve sizinle iletişim kurmak için kullanılır.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Bilgilerin Kullanımı{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Topladığımız bilgileri aşağıdaki amaçlarla kullanabiliriz:{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}- Size hizmetlerimizi sunmak ve taleplerinizi yerine getirmek{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}- Web sitemizi ve hizmetlerimizi iyileştirmek{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}- Size önemli güncellemeler ve bilgilendirmeler göndermek{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}- Yasal yükümlülüklere uymak{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Bilgilerin Paylaşımı{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}PREMIER LEGAL SOLUTIONS, kişisel bilgilerinizi üçüncü taraflarla satmaz, kiralamaz veya paylaşmaz. Ancak, bilgilerinizi aşağıdaki durumlarda paylaşabiliriz:{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}- Yasal gerekliliklere uymak için{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}- Web sitemizin güvenliğini korumak için{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}- Size hizmet sunmamıza yardımcı olan hizmet sağlayıcılarla{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Bilgilerin Güvenliği{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Kişisel bilgilerinizin güvenliği bizim için önemlidir. Bilgilerinizi yetkisiz erişime, kullanıma veya ifşaya karşı korumak için gerekli tüm teknik ve idari önlemleri alıyoruz. Ancak, internet üzerinden veri iletiminin tamamen güvenli olmadığını ve bu nedenle bize aktardığınız bilgilerinizin güvenliğini garanti edemeyeceğimizi unutmayın.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Çerezler ve Benzer Teknolojiler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Web sitemizde, kullanıcı deneyiminizi geliştirmek için çerezler ve benzer izleme teknolojileri kullanabiliriz. Çerezler, tarayıcınıza yerleştirilen küçük metin dosyalarıdır ve web sitemizi daha etkili bir şekilde kullanmanıza olanak tanır. Çerez ayarlarınızı tarayıcınızdan yönetebilir ve çerezleri devre dışı bırakabilirsiniz. Ancak, çerezleri devre dışı bırakmanız durumunda, web sitemizin bazı özelliklerinden tam anlamıyla yararlanamayabilirsiniz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Üçüncü Taraf Bağlantılar{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Web sitemiz, üçüncü taraf web sitelerine bağlantılar içerebilir. Bu sitelerin gizlilik uygulamaları bizim kontrolümüzde değildir ve bu politikada belirtilen uygulamalardan farklı olabilir. Üçüncü taraf sitelere kişisel bilgilerinizi vermeden önce, onların gizlilik politikalarını incelemenizi öneririz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}Gizlilik Politikasındaki Değişiklikler{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Bu gizlilik politikası zaman zaman güncellenebilir. Herhangi bir değişiklik durumunda, bu sayfa üzerinden güncellenmiş politikayı yayınlayacağız. Gizlilik politikamızdaki değişikliklerden haberdar olmak için bu sayfayı düzenli olarak kontrol etmenizi öneririz.{"\n    "}
			</Text>
			{"    "}
			<Text as="h3" font="--headline3" margin="40px 0 0 0">
				{"        "}İletişim{"\n    "}
			</Text>
			{"    "}
			<Text as="p" font="--base" margin="20px 0 20px 0">
				{"        "}Bu gizlilik politikası ile ilgili sorularınız veya endişeleriniz varsa, bizimle şu iletişim bilgileri üzerinden iletişime geçebilirsiniz:{" "}
				<Link href="mailto:info@premierlegalsolutions.com">
					info@premierlegalsolutions.com
				</Link>
				{"    "}
			</Text>
		</Section>
		<Components.Newfooter />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});